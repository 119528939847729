import { Avatar, AvatarProps } from "react-daisyui";
import { ComponentSize } from "react-daisyui/dist/types";
import { colourClassForHex } from "src/core/components/Card";

interface Props extends AvatarProps {
    person: { id: string; firstName: string | null; lastName: string | null };
}

const sizeMap: Record<ComponentSize | number, string> = {
    lg: "w-32 h-32",
    md: "w-24 h-24",
    sm: "w-14 h-14",
    xs: "w-10 h-10",
};

export const PersonAvatar = (props: Props) => {
    const { person, ...avatarProps } = props;

    const sizeClass = sizeMap[avatarProps.size || "md"];

    const letters = (person.firstName?.charAt(0) || "?") + (person.lastName?.charAt(0) || "?");

    const colourHex = person.id.slice(1, 7);

    const lettersColorClass = colourClassForHex(colourHex);

    return (
        <Avatar
            {...avatarProps}
            className={["placeholder mask mask-squircle", props.className].filter(Boolean).join(" ")}
        >
            <div style={{ backgroundColor: `#${colourHex}` }} className={`${sizeClass} ${lettersColorClass}`}>
                <span>{letters}</span>
            </div>
        </Avatar>
    );
};
