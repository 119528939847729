import { Icon } from "@tabler/icons-react";
import { ReactNode } from "react";
import { Button } from "react-daisyui";
import { useNavigate } from "react-router-dom";

interface Props {
    className?: string;

    icon?: Icon;
    title: string;
    message?: ReactNode;

    monoMessage?: string;

    action?: ReactNode;

    errorObj?: Error;

    /** e.g `"text-5xl"` */
    sizeClassName?: string;

    /** e.g. `"text-center"` */
    alignmentClassName?: string;
}

export const ActionableMessage = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div
            className={[
                "sm:flex sm:flex-row justify-center items-center p-10 border border-gray-400 rounded-lg",
                props.className,
            ]
                .filter(Boolean)
                .join(" ")}
        >
            <div
                className={`flex-col flex self-center sm:max-w-2xl xl:max-w-4xl z-10 pl-3 dark:text-white ${
                    props.alignmentClassName ?? "text-center"
                }`}
            >
                {props.icon && <props.icon className="text-9xl mx-auto mb-4" />}

                <p className={`mb-3 font-bold ${props.sizeClassName ?? "text-5xl"}`}>{props.title}</p>

                <div>{props.message ?? "Please try again, or contact church if you think something's wrong."}</div>

                <div className={`${props.alignmentClassName ?? "text-center"} pt-5 mb-10`}>
                    {props.action === undefined ? (
                        <Button color="primary" onClick={() => navigate(-1)}>
                            Go back
                        </Button>
                    ) : (
                        props.action
                    )}
                </div>

                {props.monoMessage && <p className="font-mono text-sm">{props.monoMessage}</p>}
                {props.errorObj && <p className="font-mono text-sm opacity-60">{props.errorObj.message}</p>}
            </div>
        </div>
    );
};
