import type { RouteObject } from "react-router-dom";

export const songLibrariesRoutes: RouteObject[] = [
    {
        index: true,

        lazy: () => import("./routes/_index/SongLibrariesIndexScreen"),
    },

    {
        path: ":songLibraryId",
        lazy: () => import("./routes/song-libraries/SongLibraryLayout"),

        children: [
            {
                path: "view-song/:songLibrarySongId",

                lazy: () => import("./routes/song-libraries/view-song/SongLibraryViewSongFragment"),
            },

            {
                path: "add-a-song",
                element: <p>🚧</p>,
            },
        ],
    },
];
