import { ReactNode } from "react";
import { Icon, IconHome } from "@tabler/icons-react";
import { Link, Params, useMatches } from "react-router-dom";

export interface Breadcrumb {
    linkTo: string;
    label: ReactNode;
}

interface Match<H = unknown, D = unknown> {
    id: string;
    pathname: string;
    params: Params<string>;
    data: D;
    handle: H;
}

const matchHandleIsCrumb = (match: Match): match is Match<{ crumb: string; icon?: Icon }> =>
    typeof match.handle === "object" && match.handle !== null && "crumb" in match.handle;

interface Props {
    showLastBreadcrumb?: boolean;
}

export const RouteBreadcrumbs = (props: Props) => {
    const matches: Match[] = useMatches();

    const crumbs = matches.filter(matchHandleIsCrumb);

    const lastCrumb = crumbs.pop();

    // No crumbs
    if (!lastCrumb) {
        return null;
    }

    return (
        <nav className="breadcrumbs text-sm">
            <ul>
                {crumbs.map((crumb, index) => (
                    <li role="navigation" key={crumb.id}>
                        <Link to={crumb.pathname}>
                            {index === 0 && <IconHome className="mr-1" />}{" "}
                            {crumb.handle.icon && <crumb.handle.icon className="mr-1" />} {crumb.handle.crumb}
                        </Link>
                    </li>
                ))}

                {lastCrumb && (
                    <li role="navigation" className="opacity-70">
                        {lastCrumb.handle.icon && <lastCrumb.handle.icon className="mr-1" />} {lastCrumb.handle.crumb}
                    </li>
                )}
            </ul>
        </nav>
    );
};
