import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { SWRConfig } from "swr";
import { useEffect, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../firebase";
import { useRouteCans } from "../hooks/useRouteCans";
import { ErrorScreen } from "src/core/components/ErrorScreen";

interface Props {
    publicPermitted?: boolean;
}

export const ProtectedRoute = ({ publicPermitted }: Props) => {
    const auth = useAuth();

    const from = encodeURIComponent(window.location.pathname);

    const { hasPermission } = useRouteCans();

    const swrConfig = useMemo(
        () => ({
            fetcher: (url: string) => {
                return auth.client?.get(url, {}).then((response) => response.data);
            },
        }),
        [auth.client]
    );

    useEffect(() => {
        logEvent(firebaseAnalytics, "page_view", {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: document.title,
        });
    }, [from]);

    if (auth.person === undefined) {
        return <Navigate to={`/login?from=${from}`} replace />;
    }

    const userHasNoRoles = auth.person && auth.person.user.roles.length === 0;

    if (!publicPermitted && userHasNoRoles) {
        return <Navigate to="/" replace />;
    } else if (!hasPermission) {
        return <ErrorScreen title="You don't have permission to view this page" monoMessage={`From: ${from}`} />;
    }

    return (
        <SWRConfig value={swrConfig}>
            <Outlet />
        </SWRConfig>
    );
};
