import { PropsWithChildren } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export const SidebarAppsMenuNavLink = ({
    className,
    children,
    ...props
}: PropsWithChildren<Omit<NavLinkProps, "children">>) => (
    <NavLink {...props} className={`py-3 lg:py-2 ${className}`}>
        {children}
    </NavLink>
);
