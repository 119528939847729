import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { PureAbility, subject } from "@casl/ability";
import type { AppSubjects, LbcrmSubjects } from "@lbcrm-api/casl/app.subjects";
import { PrismaQuery } from "@casl/prisma";

export type Action = "manage" | "create" | "read" | "update" | "delete";

export class AppAbility extends PureAbility<[Action, AppSubjects], PrismaQuery> {}

export const AbilityContext = createContext<AppAbility>(new AppAbility());
export const Can = createContextualCan(AbilityContext.Consumer);

export const lbcrmSubject = <T extends keyof LbcrmSubjects>(type: T, object: LbcrmSubjects[T]) => subject(type, object);
