import { Navigate, RouteObject } from "react-router-dom";
import { directoryRoutes } from "./directory/directoryRoutes";
import { tagsRoutes } from "./tags/tagsRoutes";
import { routeCan } from "src/authentication/routeCan";
import { Action } from "src/casl/Action";

export const peopleRoutes: RouteObject[] = [
    // Layout
    {
        children: [
            // Default // TODO people dashboard
            { index: true, element: <Navigate to="directory" /> },

            /** People modules */
            {
                path: "directory",
                children: directoryRoutes,
            },

            { path: "tags", children: tagsRoutes, handle: routeCan(Action.Manage, "Tag") },
        ],
    },
];
