import { Axios } from "axios";
import { createContext } from "react";
import { LbcrmType } from "src/core/LbcrmType";

export type AblePerson = LbcrmType.Person & {
    user: LbcrmType.User & {
        roles: LbcrmType.UserRoleAssignment[];
    };

    _count: {
        responsiblePeople: number;
        personResponsibilities: number;
    };
};

export interface AuthenticationContextValue {
    /**
     * The person that is currently logged in.
     *
     * If this is `null`, then the user is authenticated, but hasn't created a
     * `Person` yet.
     *
     * If this is `undefined`, then the user is not authenticated.
     */
    person: AblePerson | null | undefined;
    error: Error | undefined;

    client: Axios | undefined;

    handleLogin(): Promise<void>;
    logout(): void;
    refreshProfile(): Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationContextValue | null>(null);
