import { ReactNode } from "react";
import { IconMoodSad } from "@tabler/icons-react";
import { ActionableMessage } from "./ActionableMessage";

interface Props {
    title: string;
    message?: ReactNode;

    monoMessage?: string;

    action?: ReactNode;

    errorObj?: Error;
}

export const ErrorScreen = (props: Props) => (
    <ActionableMessage className="min-h-screen" icon={IconMoodSad} {...props} />
);
