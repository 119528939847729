import { forwardRef } from "react";
import { Button, Modal } from "react-daisyui";
import { IconX } from "@tabler/icons-react";
import { Heading } from "src/core/components/Heading";

export const PrivacyPolicyDialog = forwardRef<HTMLDialogElement>((_, ref) => (
    <Modal ref={ref} className="text-left" responsive>
        <Modal.Body>
            <form method="dialog">
                <Modal.Header className="flex">
                    <Heading level="h4" className="grow">
                        Privacy policy
                    </Heading>

                    <Button startIcon={<IconX />} color="ghost" size="sm">
                        <span className="hidden">Close</span>
                    </Button>
                </Modal.Header>

                <div className="prose overflow-auto">
                    <Heading level="h5">1. Information We Collect</Heading>

                    <p>
                        <strong>Personal Identification Information:</strong> We collect the full name and mobile number
                        of users. When giving consent, users provide their child's full name and event-specific
                        information such as dietary requirements and health conditions.
                    </p>

                    <Heading level="h5">2. Purposes of Data Collection</Heading>

                    <p>
                        <strong>Consent:</strong> Information is collected to ensure children are allowed to participate
                        in events, and to have a point of contact for emergencies. Data is strictly used for these
                        purposes and not for marketing or promotional activities.
                    </p>

                    <Heading level="h5">3. Data Storage and Security</Heading>

                    <p>
                        User data is stored on a cloud server, encrypted in transit. Access is restricted to volunteers
                        who need the information, with different levels of access based on roles. Access to sensitive
                        information is audited and logged. Data is backed up nightly, and backups are encrypted at rest.
                    </p>

                    <Heading level="h5">4. Data Sharing</Heading>

                    <p>
                        No personally identifiable information (PII) is shared with third parties. Device information is
                        collected for analytics and error tracing. Authentication is handled by Google Firebase
                        Authentication, which only stores phone numbers.
                    </p>

                    <Heading level="h5">5. Parental Consent</Heading>

                    <p>
                        Users under 18 cannot grant themselves permission or consent. Consent can only be granted by
                        users aged 18 and above.
                    </p>

                    <Heading level="h5">6. Communication</Heading>

                    <p>
                        The app does not send communications to users, except for necessary one-time passwords (OTPs)
                        for authentication. Users cannot opt out of OTPs as they are essential for app authentication.
                    </p>

                    <Heading level="h5">7. Data Retention</Heading>

                    <p>Information is retained for three years from the last contact with an individual.</p>

                    <Heading level="h5">8. Policy Updates</Heading>

                    <p>
                        Users will be invited to review the privacy policy on their next sign-in if changes occur. No
                        other communication methods are planned for policy changes.
                    </p>

                    <Heading level="h5">9. User Rights</Heading>

                    <p>
                        Users have rights under GDPR and can exercise them by contacting anyone in church leadership or
                        by emailing info@lurganbaptist.church.
                    </p>

                    <Heading level="h5">10. Contact Information</Heading>

                    <p>For privacy-related inquiries, contact Chris Wilkinson in church.</p>
                </div>

                <Modal.Actions>
                    <Button formMethod="dialog">Close</Button>
                </Modal.Actions>
            </form>
        </Modal.Body>
    </Modal>
));
