import { useHttpClient } from "src/authentication/hooks/useHttpClient";
import type { MinistryInvolvementsResponse } from "@lbcrm-api/ministries/ministry-involvements/ministry-involvements.responses";
import useSWR from "swr";

export const useMinistryInvolvements = () => {
    const client = useHttpClient();

    const { data, error, mutate } = useSWR("ministry-involvements/ministries", async (url) => {
        const response = await client.get<MinistryInvolvementsResponse[]>(url);

        return response.data;
    });

    return {
        ministryInvolvements: data,
        error,

        mutate,
    };
};
