import { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface Props {}

export const Portal = ({ children }: PropsWithChildren<Props>) => {
    const [portalElement] = useState<HTMLDivElement>(document.createElement("div"));

    useEffect(() => {
        if (!portalElement) {
            return;
        }

        document.body.appendChild(portalElement);

        return () => {
            if (portalElement) {
                document.body.removeChild(portalElement);
            }
        };
    }, [portalElement]);

    return portalElement ? createPortal(children, portalElement) : null;
};
