import { Outlet, ScrollRestoration } from "react-router-dom";
import { AuthenticationProvider } from "./authentication/context/AuthenticationProvider";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReloadPrompt } from "./core/service-worker/ReloadPrompt";

import "./Toastify.css";

export const App = () => (
    <>
        <Helmet titleTemplate="%s ― Lurgan Baptist Church Hub" defaultTitle="Lurgan Baptist Church Hub" />

        <ToastContainer position="bottom-right" stacked />

        <ScrollRestoration getKey={(location) => location.pathname} />

        <AuthenticationProvider>
            <Outlet />
        </AuthenticationProvider>

        <ReloadPrompt />
    </>
);
