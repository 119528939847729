import { PropsWithChildren, memo } from "react";

interface Props {}

export const SplitImageLayout = memo((props: PropsWithChildren<Props>) => (
    <main
        className="bg-no-repeat bg-cover bg-center"
        style={{
            backgroundImage: "url(https://www.lurganbaptist.church/wp-content/uploads/2019/04/church-exterior-2.jpg)",
        }}
    >
        <div className="grid md:grid-cols-2">
            <div className="relative">
                <div className="absolute bg-gradient-to-b from-base-300 to-base-200 opacity-75 inset-0 z-0" />
            </div>

            <div className="flex h-screen bg-base-100">{props.children}</div>
        </div>
    </main>
));
