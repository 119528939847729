import { Icon } from "@tabler/icons-react";
import { ComponentProps } from "react";

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type Props<T extends HeadingLevel> = ComponentProps<T> & {
    level: HeadingLevel;
    icon?: Icon;
};

const HEADING_CLASSES: Record<HeadingLevel, string> = {
    h1: "text-2xl lg:text-3xl",
    h2: "text-xl lg:text-2xl",
    h3: "text-lg lg:text-xl",
    h4: "text-md lg:text-lg",
    h5: "text lg:text-md",
    h6: "text lg:text",
};

export const Heading = <T extends HeadingLevel>(props: Props<T>) => {
    const { level, className, icon: Icon, children, ...elementProps } = props;

    const Element = level;

    const classes = [HEADING_CLASSES[level], "font-bold my-2", className].filter(Boolean);

    return (
        <Element {...elementProps} className={classes.join(" ")}>
            {Icon && <Icon className="inline mr-2 mb-1" />}

            {children}
        </Element>
    );
};

Heading.H1 = (props: ComponentProps<"h1">) => <Heading level="h1" {...props} />;
Heading.H2 = (props: ComponentProps<"h2">) => <Heading level="h2" {...props} />;
Heading.H3 = (props: ComponentProps<"h3">) => <Heading level="h3" {...props} />;
Heading.H4 = (props: ComponentProps<"h4">) => <Heading level="h4" {...props} />;
Heading.H5 = (props: ComponentProps<"h5">) => <Heading level="h5" {...props} />;
Heading.H6 = (props: ComponentProps<"h6">) => <Heading level="h6" {...props} />;
