import { PropsWithChildren } from "react";
import { IconArrowLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";

interface Props {
    to?: string;

    className?: string;
}

export const BackLink = (props: PropsWithChildren<Props>) => (
    <Link
        to={props.to || ".."}
        className={[
            "link link-hover inline-flex items-center text-sm p-2 -ml-2 hover:bg-base-200 rounded-lg transition-all",
            props.className,
        ]
            .filter(Boolean)
            .join(" ")}
    >
        <IconArrowLeft className="mr-1" /> {props.children}
    </Link>
);
