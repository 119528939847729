import type { AppSubjects } from "@lbcrm-api/casl/app.subjects";
import { Params } from "react-router-dom";
import { Action } from "src/casl/Action";

export interface RouteCan {
    i: Action;
    a: AppSubjects | ((params: Readonly<Params<string>>) => AppSubjects);
}

export const routeCan = (i: Action, a: RouteCan["a"]) => ({ can: { i, a } satisfies RouteCan });
