import { subject } from "@casl/ability";
import type { RouteObject } from "react-router-dom";
import { routeCan } from "src/authentication/routeCan";
import { Action } from "src/casl/Action";

export const ministrySeasonEventsRoutes: RouteObject[] = [
    { index: true, lazy: () => import("./MinistrySeasonEventsScreen") },

    {
        path: "view-event/:eventId",
        // TODO replace with dynamic route handle
        handle: routeCan(Action.Read, "CalendarEvent"),

        /** Use with useMinistrySeasonEventContext */
        lazy: () => import("./view-event/ViewMinistrySeasonEventOutlet"),

        children: [
            { index: true, lazy: () => import("./view-event/index/ViewMinistrySeasonEventIndexScreen") },

            {
                path: "check-in-attendants",
                handle: routeCan(Action.Read, "CalendarEventAttendance"),
                lazy: () => import("./view-event/check-in-attendants/CheckInAttendantsScreen"),
            },

            {
                path: "details",
                children: [
                    {
                        index: true,
                        lazy: () => import("./view-event/details/MinistrySeasonEventDutiesScreen"),
                    },
                ],
            },

            {
                path: "bookings",
                handle: routeCan(Action.Read, "CalendarEventPersonBooking"),
                lazy: () => import("./view-event/bookings/MinistrySeasonEventBookingsScreen"),
            },

            {
                path: "new-booking",
                handle: routeCan(Action.Create, "CalendarEventPersonBooking"),
                lazy: () => import("./view-event/new-booking/MinistrySeasonEventNewBookingScreen"),
            },

            {
                path: "groups",
                lazy: () => import("./view-event/groups/MinistrySeasonEventGroupsScreen"),
            },

            {
                path: "bus-journeys",
                handle: routeCan(Action.Read, "VehicleEvent"),
                children: [
                    {
                        index: true,
                        lazy: () => import("./view-event/bus-journeys/MinistrySeasonEventBusJourneysScreen"),
                    },

                    {
                        path: "go/:vehicleEventId",
                        lazy: () => import("./view-event/bus-journeys/go/MinistrySeasonEventBusJourneyGoScreen"),
                    },

                    {
                        path: "edit/:vehicleEventId",
                        lazy: () => import("./view-event/bus-journeys/edit/MinistrySeasonEventBusJourneyEditScreen"),
                    },
                ],
            },

            {
                path: "awards",
                handle: routeCan(Action.Read, "MinistryAward"),
                lazy: () => import("./view-event/awards/MinistrySeasonEventAwardsScreen"),
            },

            {
                path: "programme",
                handle: routeCan(Action.Read, ({ ministryId }) =>
                    subject("CalendarEventProgrammeItem", {
                        calendarEvent: { ministrySeason: { ministry: { id: ministryId } } },
                    })
                ),
                lazy: () => import("./view-event/programme-items/MinistrySeasonEventProgrammeItemsScreen"),
            },

            {
                path: "configuration",
                handle: routeCan(Action.Update, "CalendarEvent"),
                lazy: () => import("./view-event/configuration/MinistrySeasonEventConfigurationScreen"),
            },
        ],
    },

    {
        path: "create-event",
        handle: routeCan(Action.Create, "CalendarEvent"),
        lazy: () => import("./create-event/MinistrySeasonCreateEventScreen"),
    },

    {
        path: "batch-create-events",
        handle: routeCan(Action.Create, "CalendarEvent"),
        lazy: () => import("./batch-create-events/MinistrySeasonBatchCreateEventsScreen"),
    },

    {
        path: "event-groups",
        handle: routeCan(Action.Read, "CalendarEventGroup"),
        children: [
            { index: true, lazy: () => import("./event-groups/MinistrySeasonEventGroupsScreen") },

            {
                path: "create",
                handle: routeCan(Action.Create, "CalendarEventGroup"),
                lazy: () =>
                    import("./event-groups/create-ministry-season-event-group/CreateMinistrySeasonEventGroupScreen"),
            },
        ],
    },
];
