import { useAuth } from "./useAuth";

/**
 * @returns an http client that has valid authentication, ready to use with APIs
 */
export const useHttpClient = () => {
  const context = useAuth();

  if (!context.client) {
    throw new Error("No valid client has been set up");
  }

  return context.client;
};
