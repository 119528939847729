import { useContext } from "react";
import { AuthenticationContext } from "../context/AuthenticationContext";

export const useAuth = () => {
    const context = useContext(AuthenticationContext);

    if (context === null) {
        throw new Error("useAuth must be called in a descendant of an AuthenticationProvider");
    }

    return context;
};
