import { createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { ProtectedRoute } from "./authentication/components/ProtectedRoute";
import { protectedRoutes } from "./protectedRoutes";
import { publicRoutes } from "./public/publicRoutes";
import { ErrorScreen } from "./core/components/ErrorScreen";
import { NavBarLayout } from "./core/components/NavBarLayout";
import { RouteNotFoundScreen } from "./core/components/RouteNotFoundScreen";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: (
            <ErrorScreen
                title="Something went wrong"
                message="Something happened that we couldn't recover from. Try refreshing the page."
            />
        ),
        children: [
            {
                index: true,
                lazy: () => import("./public/PublicIndex"),
            },

            {
                path: "login",
                lazy: () => import("./public/public-login/PublicLoginScreen"),
                errorElement: (
                    <ErrorScreen
                        title="Something went wrong"
                        message="Something happened that we couldn't recover from. Try closing and reopening the app."
                    />
                ),
            },

            { path: "set-up-your-profile", lazy: () => import("./profile/SetUpYourProfileScreen") },

            // NavBarLayout routes
            {
                element: <NavBarLayout />,

                errorElement: (
                    <ErrorScreen
                        title="Something went wrong"
                        message="Something happened that we couldn't recover from. Try closing and reopening the app."
                    />
                ),

                children: [
                    {
                        path: "public",
                        children: publicRoutes,
                        errorElement: (
                            <ErrorScreen
                                title="This screen's not working right"
                                message="Something happened that we couldn't recover from. Try another screen or closing and opening the app again."
                            />
                        ),
                    },

                    {
                        element: <ProtectedRoute />,
                        children: protectedRoutes,
                        errorElement: (
                            <ErrorScreen
                                title="This screen's not working right"
                                message="Something happened that we couldn't recover from. Try another screen or closing and opening the app again."
                            />
                        ),
                    },
                ],
            },

            // Catch all / 404
            { path: "*", element: <RouteNotFoundScreen /> },
        ],
    },
]);
