import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import { useAuth } from "src/authentication/hooks/useAuth";
import { SplitImageLayout } from "./SplitImageLayout";
import { Helmet } from "react-helmet";
import { IconUnlink } from "@tabler/icons-react";
import { Button } from "react-daisyui";
import { ButtonLink } from "./ButtonLink";

export const RouteNotFoundScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const path = useResolvedPath(location.pathname);
    const { person } = useAuth();

    return (
        <SplitImageLayout>
            <Helmet>
                <title>Page not found</title>
            </Helmet>

            <div className="mx-auto md:my-auto text-center">
                <div className="p-6 md:p-12 sm:max-w-md md:max-w-l xl:max-w-xl">
                    <IconUnlink className="mb-2 text-7xl mx-auto" />
                    <h1 className="mb-3 font-bold text-5xl">Page not found</h1>

                    <p className="pr-3">This page either doesn't exist, or you don't have permission to see it.</p>
                    <p className="pr-3">Contact church if you think there's been a mistake.</p>

                    <div className="text-center pt-5 flex flex-wrap gap-2 justify-center">
                        {location.key !== "default" && (
                            <Button color="primary" onClick={() => navigate(-1)}>
                                Go back
                            </Button>
                        )}

                        <ButtonLink to="/" className="btn-outline">
                            Go home
                        </ButtonLink>
                    </div>

                    <p className="font-mono text-sm pt-10">
                        Path: {path.pathname}
                        <br />
                        For: {person?.id ?? "not authenticated"}
                    </p>
                </div>
            </div>
        </SplitImageLayout>
    );
};
