import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-daisyui";
import { IconRefreshAlert } from "@tabler/icons-react";

import { useRegisterSW } from "virtual:pwa-register/react";
import { toast, type Id } from "react-toastify";

export const ReloadPrompt = () => {
    const [busy, setBusy] = useState(false);

    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const toastRef = useRef<Id>();

    const {
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(_swUrl, r) {
            if (r) {
                r.update().catch();

                setInterval(() => {
                    r.update().catch();
                }, 20_000);
            }
        },
        onRegisterError() {
            // TODO Intentionally no action for now
        },
    });

    const close = useCallback(() => {
        setNeedRefresh(false);

        if (toastRef.current) {
            toast.dismiss(toastRef.current);
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    }, [setNeedRefresh]);

    useEffect(() => {
        if (needRefresh && !busy) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }

            timerRef.current = setTimeout(() => {
                if (toastRef.current) {
                    toast.update(toastRef.current, {
                        isLoading: false,
                        autoClose: false,
                        closeOnClick: false,
                        type: "success",
                        render: "The app will update now.",
                    });
                }

                setBusy(true);
                updateServiceWorker(true);
            }, 10_000);

            if (toastRef.current) {
                toast.dismiss(toastRef.current);
            }

            toastRef.current = toast.warning(
                <div className="justify-center font-medium flex flex-wrap items-center gap-4 lg:gap-10">
                    <div>
                        <p className="font-bold">
                            <IconRefreshAlert className="inline mb-1 mr-1 w-5 h-5" /> There's an update for Hub.
                        </p>

                        <p className="opacity-70">It only takes a few seconds to refresh.</p>
                    </div>

                    <div className="flex items-center gap-2">
                        <Button
                            size="sm"
                            loading={busy}
                            color="primary"
                            type="button"
                            onClick={() => {
                                setBusy(true);

                                updateServiceWorker(true);
                            }}
                        >
                            Update
                        </Button>

                        <Button color="ghost" size="sm" variant="outline" onClick={close} type="button">
                            Not now
                        </Button>
                    </div>
                </div>,
                {
                    closeOnClick: false,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    autoClose: 10_000,
                }
            );
        } else {
            if (toastRef.current) {
                toast.dismiss(toastRef.current);
            }

            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        }
    }, [busy, close, needRefresh, updateServiceWorker]);

    return null;
};
