import { PropsWithChildren, ReactNode } from "react";
import { BackLink } from "./BackLink";

interface Props {
    layoutTitle?: ReactNode;
    hideBackLink?: boolean;
}

export const CenteredLayout = ({ hideBackLink, layoutTitle, children }: PropsWithChildren<Props>) => (
    <div className="px-4 pb-4 grow">
        <div className="max-w-screen-lg pt-5 mx-auto">
            {!hideBackLink && <BackLink />}
            {layoutTitle && <h2 className="text-3xl lg:text-5xl font-bold mt-2 mb-4">{layoutTitle}</h2>}

            {children}
        </div>
    </div>
);
