import { useContext } from "react";
import { useMatches, useParams } from "react-router-dom";
import { AbilityContext } from "../context/Can";
import { RouteCan } from "../routeCan";

type Handle = { can: RouteCan };

export const useRouteCans = () => {
    const matches = useMatches();
    const params = useParams();

    const routeCans = matches.map((match) => (match.handle as Handle)?.can).filter(Boolean);
    const ability = useContext(AbilityContext);

    const hasPermission =
        routeCans.length === 0 ||
        routeCans.every((routeCan) =>
            ability.can(routeCan.i, typeof routeCan.a === "function" ? routeCan.a(params) : routeCan.a)
        );

    return { hasPermission };
};
