import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./router";
import { init } from "@sentry/react";
import { StrictMode } from "react";

if (import.meta.env.VITE_ENVIRONMENT === "production") {
    init({
        dsn: "https://6a1ec15eebe76a4dd8d9fdf751ff1f06@o4506070863904768.ingest.sentry.io/4506070866132992",

        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        release: `lbcrm@${import.meta.env.VITE_RELEASE_VERSION || "dev"}`,
        environment: import.meta.env.VITE_ENVIRONMENT,
    });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>
);
