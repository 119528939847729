import { useRef } from "react";
import { PrivacyPolicyDialog } from "./PrivacyPolicyDialog";
import { Portal } from "src/core/components/portals/Portal";

export const PrivacyPolicyLink = () => {
    const ref = useRef<HTMLDialogElement>(null);

    return (
        <>
            <button
                type="button"
                onClick={() => ref.current?.showModal()}
                className="link link-hover text-center text-gray-400 text-xs"
            >
                Privacy policy
            </button>

            <Portal>
                <PrivacyPolicyDialog ref={ref} />
            </Portal>
        </>
    );
};
