import { RouteObject } from "react-router-dom";
import { routeCan } from "src/authentication/routeCan";
import { Action } from "src/casl/Action";
import { goToFirstTab } from "src/core/components/HeadedLayout";
import { ministrySeasonEventsRoutes } from "./ministry-seasons/events/ministrySeasonEventsRoutes";
import { subject } from "@casl/ability";

export const ministriesRoutes: RouteObject[] = [
    {
        children: [
            { index: true, lazy: () => import("./dashboard/MinistriesDashboardScreen") },

            {
                path: ":ministryId",
                handle: routeCan(Action.Read, "Ministry"),
                lazy: () => import("./view-ministry/MinistryLayout"),
                children: [
                    { index: true, handle: goToFirstTab },

                    {
                        path: "ministry-seasons",
                        lazy: () => import("./view-ministry/seasons/MinistrySeasonsTab"),
                    },

                    {
                        path: "migrations",
                        handle: routeCan(Action.Update, ({ ministryId }) => subject("Ministry", { id: ministryId })),
                        lazy: () => import("./view-ministry/migrations/MinistryRegistrationMigrationsTab"),
                    },

                    {
                        path: "awards",
                        handle: routeCan(Action.Update, ({ ministryId }) =>
                            subject("MinistryAward", { ministry: { id: ministryId } })
                        ),
                        lazy: () => import("./view-ministry/awards/MinistryAwardsTab"),
                    },

                    {
                        path: "teams",
                        handle: routeCan(Action.Update, ({ ministryId }) =>
                            subject("MinistryTeam", { ministry: { id: ministryId } })
                        ),
                        children: [
                            { index: true, lazy: () => import("./view-ministry/ministry-teams/MinistryTeamsTab") },

                            {
                                path: ":ministryTeamId",
                                lazy: () =>
                                    import("./view-ministry/ministry-teams/ministry-team/MinistryTeamManagementScreen"),
                            },
                        ],
                    },

                    {
                        path: "positions",
                        handle: routeCan(Action.Update, ({ ministryId }) => subject("Ministry", { id: ministryId })),
                        lazy: () => import("./view-ministry/ministry-positions/MinistryPositionsTab"),
                    },
                ],
            },

            /** MINISTRY SEASONS */
            {
                path: ":ministryId/ministry-seasons/:ministrySeasonId",

                handle: routeCan(Action.Read, "MinistrySeason"),
                lazy: () => import("./ministry-seasons/MinistrySeasonLayout"),

                children: [
                    {
                        index: true,
                        handle: routeCan(Action.Read, "MinistrySeason"),
                        lazy: () => import("./ministry-seasons/_index/MinistrySeasonIndexScreen"),
                    },

                    {
                        path: "events",
                        handle: routeCan(Action.Read, "CalendarEvent"),

                        children: ministrySeasonEventsRoutes,
                    },

                    {
                        path: "duties",
                        handle: routeCan(Action.Read, "CalendarEvent"),
                        lazy: () => import("./ministry-seasons/duties/MinistrySeasonDutiesScreen"),
                    },

                    {
                        path: "attendance",
                        handle: routeCan(Action.Read, "CalendarEventAttendance"),
                        lazy: () => import("./ministry-seasons/attendance/MinistrySeasonAttendanceOverviewScreen"),
                    },
                    {
                        path: "registrations",
                        handle: routeCan(Action.Read, "MinistrySeasonRegistration"),
                        children: [
                            {
                                index: true,
                                lazy: () => import("./ministry-seasons/registrants/MinistrySeasonRegistrationsScreen"),
                            },

                            {
                                path: ":ministrySeasonRegistrationId",
                                lazy: () => import("./ministry-seasons/registrants/MinistrySeasonRegistrationScreen"),
                            },
                        ],
                    },
                    {
                        path: "rosters",
                        handle: routeCan(Action.Read, "MinistrySeasonAttendanceRoster"),
                        children: [
                            {
                                index: true,
                                lazy: () =>
                                    import(
                                        "./ministry-seasons/attendance-rosters/MinistrySeasonAttendanceRostersScreen"
                                    ),
                            },

                            {
                                path: ":ministrySeasonAttendanceRosterId",
                                lazy: () =>
                                    import(
                                        "./ministry-seasons/attendance-rosters/view-attendance-roster/ViewMinistrySeasonAttendanceRosterScreen"
                                    ),
                            },
                        ],
                    },
                    {
                        path: "bus-lists",
                        handle: routeCan(Action.Read, "VehicleStopList"),
                        lazy: () =>
                            import("./ministry-seasons/vehicle-stop-lists/ViewMinistrySeasonVehicleStopListsScreen"),
                    },

                    {
                        path: "configuration",
                        handle: routeCan(Action.Update, "MinistrySeason"),
                        lazy: () => import("./ministry-seasons/configuration/MinistrySeasonConfigurationScreen"),
                    },

                    {
                        path: "duties/edit",
                        handle: routeCan(Action.Update, "MinistryTeamEventDutyAssignment"),
                        lazy: () => import("./ministry-seasons/duties/edit-duties/EditDutiesScreenOutlet"),

                        children: [
                            {
                                index: true,

                                lazy: () => import("./ministry-seasons/duties/edit-duties/EditDutiesTeamListScreen"),
                            },

                            {
                                path: ":ministryTeamId",
                                lazy: () => import("./ministry-seasons/duties/edit-duties/EditDutiesTeamScreen"),

                                children: [
                                    {
                                        index: true,
                                        lazy: () => import("./ministry-seasons/duties/edit-duties/rotas/RotasTab"),
                                    },

                                    {
                                        path: "away-dates",
                                        lazy: () =>
                                            import("./ministry-seasons/duties/edit-duties/away-dates/AwayDatesTab"),
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "bus-lists/view-list/:vehicleStopListId",
                        handle: routeCan(Action.Read, "VehicleStopList"),
                        lazy: () =>
                            import(
                                "./ministry-seasons/vehicle-stop-lists/view-list/ViewMinistrySeasonVehicleStopListScreen"
                            ),
                    },
                ],
            },
        ],
    },
];
