import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCV8dYpgxrytfA5j9kJxFr87alzbP0oypY",
    authDomain: "lbcrm-90140.firebaseapp.com",
    projectId: "lbcrm-90140",
    appId: "1:411004846377:web:ed11126607cbbee8ebf738",

    measurementId: "G-8E4ETCTP17",
});

export const firebaseAuth = getAuth(firebaseApp);

if (process.env.NODE_ENV === "development") {
    const { connectAuthEmulator } = await import("firebase/auth");

    connectAuthEmulator(firebaseAuth, `http://${window.location.hostname}:9099`, { disableWarnings: true });
}

export const firebaseAnalytics = getAnalytics(firebaseApp);
