import { PrivacyPolicyLink } from "./PrivacyPolicyLink";

export const CopyrightFooter = () => (
    <>
        <p className="text-center text-gray-400 text-xs">
            Copyright © 2022—{new Date().getFullYear()}&nbsp;&bull;&nbsp;
            <a href="https://www.lurganbaptist.church" target="_blank" rel="noreferrer">
                Lurgan Baptist Church
            </a>
        </p>

        <p className="text-center text-gray-400 text-xs">
            <PrivacyPolicyLink />
            &nbsp;&bull;&nbsp;
            {import.meta.env.VITE_RELEASE_VERSION || "dev"}
        </p>
    </>
);
